import { FC, useState } from "react";
import AlertCircleIcon from "../../../../../apps/reactfront/src/Components/Icons/AlertCircleIcon";
import CrossCircleIcon from "../../../../../apps/reactfront/src/Components/Icons/CrossCircleIcon";

type ConfirmDeleteButtonProps = {
  title?: string;
  deleteAction: () => void;
};

export const ConfirmDeleteButton: FC<ConfirmDeleteButtonProps> = ({
  title,
  deleteAction,
}) => {
  const [confirmDelete, setConfirmDelete] = useState(false);

  if (confirmDelete) {
    return (
      <AlertCircleIcon
        className={"cursor-pointer hover:drop-shadow"}
        title={title}
        action={deleteAction}
      />
    );
  }

  return (
    <CrossCircleIcon
      className={"cursor-pointer hover:drop-shadow"}
      onClick={() => {
        setConfirmDelete(true);
      }}
    />
  );
};
