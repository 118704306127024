import { CompanyData } from "../../../Companies";
import React, { FC } from "react";
import { LoginMethod } from "@kamae-apps/shared/Types/Company/LoginMethod";
import { MailDomains } from "./Containers/DomainMail/MailDomains";
import { ConditionalRender } from "@kamae-apps/shared/Component/ConditionalRender/ConditionalRender";
import { MagicLinkConnection } from "./Containers/MagicLinkConnection/MagicLinkConnection";
import { SSOConnection } from "./Containers/SSOConnection/SSOConnection";
import { SCIMConnection } from "./Containers/SCIMConnection/SCIMConnection";

type CryptrContainerProps = {
  company: CompanyData;
};

export const CryptrContainer: FC<CryptrContainerProps> = ({ company }) => {
  return (
    <div className={"flex w-full flex-col justify-between"}>
      <MailDomains company={company} />
      <div className={"flex justify-evenly p-3"}>
        <ConditionalRender
          condition={company.loginMethod === LoginMethod.MagicLink}
        >
          <MagicLinkConnection company={company} />
        </ConditionalRender>
        <ConditionalRender condition={company.sso_enabled}>
          <SSOConnection
            company={company}
            first={company.loginMethod !== LoginMethod.MagicLink}
          />
        </ConditionalRender>
        <ConditionalRender condition={company.dir_sync_enabled}>
          <SCIMConnection company={company} />
        </ConditionalRender>
      </div>
    </div>
  );
};
