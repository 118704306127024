import Spinner from "@kamae-apps/shared/Component/Button/Spinner";
import Checked from "../../../../../../Icons/Checked";
import UnChecked from "../../../../../../Icons/UnChecked";
import Divider from "@kamae-apps/shared/Component/Divider/Divider";
import Input from "@kamae-apps/shared/Component/Input/Input";
import clsx from "clsx";
import PrimaryButton from "@kamae-apps/shared/Component/Button/PrimaryButton";
import { validateEmail } from "../../../../../../../../../reactfront/src/utils";
import { apiRequest } from "@kamae-apps/shared/utils";
import { errorToast } from "@kamae-apps/shared/Component/Toast/ToastBuilder";
import React, { FC } from "react";
import { CompanyData } from "../../../../../Companies";
import { useSSOConnection } from "./useSSOConnection";
import { ConditionalRender } from "@kamae-apps/shared/Component/ConditionalRender/ConditionalRender";

type SSOConnectionProps = {
  company: CompanyData;
  first: boolean;
};

export const SSOConnection: FC<SSOConnectionProps> = ({ company, first }) => {
  const {
    ssoConnectionLoading,
    ssoConnectionBadRequest,
    ssoConnection,
    isNewAdminEmailValid,
    newSsoEmail,
    resetSsoLoading,
    updateNewAdminEmail,
    resetSsoConnection,
  } = useSSOConnection(company);

  return (
    <>
      {!first && <div className={"border-1 border-gray-300"} />}
      <div className={"flex flex-col justify-start gap-2"}>
        <h1 className={"mb-3 w-full text-center text-xl"}>Configuration SSO</h1>
        <div className={"flex flex-col gap-3 p-3"}>
          {ssoConnectionLoading ? (
            <div className={"flex w-full justify-center"}>
              <Spinner
                className={"text-primary"}
                size={40}
              />
            </div>
          ) : (
            <>
              <ConditionalRender condition={ssoConnectionBadRequest}>
                <p className={"w-full text-center text-red-400"}>
                  Erreur lors du chargement des données SSO 😿
                </p>
              </ConditionalRender>
              <ConditionalRender condition={!ssoConnectionBadRequest}>
                <>
                  <div className={"flex gap-2 py-1"}>
                    {ssoConnection ? <Checked /> : <UnChecked />}
                    <p>Une connexion SSO a été créée pour cette compagnie</p>
                  </div>
                  <div className={"flex gap-2 py-1"}>
                    {(ssoConnection?.can_be_activate ||
                      ssoConnection?.activate) &&
                    !ssoConnection?.it_admin_email ? (
                      <Checked />
                    ) : (
                      <UnChecked />
                    )}
                    <p>
                      La configuration SSO est terminée côté Cryptr
                      <br />
                      {ssoConnection?.it_admin_email && (
                        <span className={"text-sm text-gray-400"}>
                          L'invitation a été envoyé à "
                          {ssoConnection.it_admin_email}" le{" "}
                          {new Date(
                            ssoConnection.invitation_sent_at as string
                          ).toLocaleDateString()}
                        </span>
                      )}
                    </p>
                  </div>
                  <div className={"flex gap-2 py-1"}>
                    {ssoConnection?.activate ? <Checked /> : <UnChecked />}
                    <p>
                      Les utilisateurs peuvent se connecter en SSO à la
                      plateforme
                    </p>
                  </div>
                </>
              </ConditionalRender>
              <Divider />
              <div
                className={"flex flex-col items-center justify-evenly gap-3"}
              >
                <h3 className={"text-center text-lg"}>
                  Reset configuration SSO
                </h3>
                <Input
                  className={clsx(
                    "!mx-0 !w-full",
                    !isNewAdminEmailValid && "border-2 border-red-400"
                  )}
                  placeholderColor={clsx(
                    !isNewAdminEmailValid && "text-red-400"
                  )}
                  placeholder={"Email admin"}
                  value={newSsoEmail}
                  disabled={resetSsoLoading || SSOConnection === undefined}
                  onChange={(e) => {
                    updateNewAdminEmail(e.target.value);
                  }}
                />
                <ConditionalRender condition={!isNewAdminEmailValid}>
                  <span className={"m-0 w-full text-left text-sm text-red-400"}>
                    L'email admin est invalide
                  </span>
                </ConditionalRender>
                <PrimaryButton
                  disabled={
                    resetSsoLoading ||
                    !isNewAdminEmailValid ||
                    typeof ssoConnection === "undefined"
                  }
                  title={"La connexion SSO Cryptr n'a pas encore été créée"}
                  onClick={resetSsoConnection}
                  spinning={resetSsoLoading}
                >
                  Reset configuration SSO
                </PrimaryButton>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
