import React, { FC } from "react";
import PrimaryButton from "@kamae-apps/shared/Component/Button/PrimaryButton";
import DangerButton from "@kamae-apps/shared/Component/Button/DangerButton";
import { CompanyData } from "../../../../../Companies";
import { useMagicLinkConnection } from "./useMagicLinkConnection";

type MagicLinkProps = {
  company: CompanyData;
};

export const MagicLinkConnection: FC<MagicLinkProps> = ({ company }) => {
  const {
    magicLinkConnection,
    noMagicLinkConnection,
    creating,
    createMagicLinkConnection,
    deleteMagicLinkConnection,
  } = useMagicLinkConnection(company);

  if (magicLinkConnection === undefined && !noMagicLinkConnection) {
    return <p>Loading</p>;
  }

  if (noMagicLinkConnection) {
    return (
      <div className={"flex w-1/3 flex-col items-center justify-center gap-2"}>
        <div className={"relative"}>
          <div
            className={
              "absolute top-[-10%] left-[-5%] h-[120%] w-[110%] animate-pulse rounded bg-orange-500/50 blur"
            }
          />
          <div
            className={
              "relative rounded border-2 border-orange-500 bg-white p-2"
            }
          >
            <p> ⚠️ Attention aucune connexion magic link ⚠️</p>
            <PrimaryButton
              disabled={creating}
              spinning={creating}
              onClick={createMagicLinkConnection}
            >
              Créer la connexion magic link
            </PrimaryButton>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={"flex w-1/3 flex-col items-center justify-center gap-2"}>
      <p>Connexion magic link opérationnelle</p>
      <DangerButton
        spinning={creating}
        disabled={creating}
        onClick={deleteMagicLinkConnection}
      >
        Suppprimer connexion magic link
      </DangerButton>
    </div>
  );
};
