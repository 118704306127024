import { CompanyData } from "../../../../../Companies";
import { useApiRequest } from "@kamae-apps/shared/Hooks/useApiRequest";
import { useCallback, useEffect, useState } from "react";
import useReload from "@kamae-apps/shared/Hooks/useReload";
import { APIError } from "@kamae-apps/shared/Types/API";
import { apiRequest } from "@kamae-apps/shared/utils";

export const useMagicLinkConnection = (company: CompanyData) => {
  const { reload, doReload } = useReload();
  const [creating, setCreating] = useState(false);
  const [noMagicLinkConnection, setNoMagicLinkConnection] = useState(false);

  const onError = useCallback((e: APIError) => {
    if (e.code === 404) {
      setNoMagicLinkConnection(true);
    }
  }, []);

  const magicLinkConnection = useApiRequest(
    `/company/${company.id}/magiclink`,
    [reload],
    {
      onError: onError,
      reset: true,
    }
  );

  const createMagicLinkConnection = () => {
    setCreating(true);
    apiRequest(`/company/${company.id}/magiclink`, {
      method: "POST",
    }).then(() => {
      setTimeout(() => {
        doReload();
        setCreating(false);
      }, 2000);
    });
  };

  const deleteMagicLinkConnection = () => {
    setCreating(true);
    apiRequest(`/company/${company.id}/magiclink`, {
      method: "DELETE",
    }).then(() => {
      setTimeout(() => {
        doReload();
        setCreating(false);
      }, 2000);
    });
  };

  useEffect(() => {
    if (magicLinkConnection !== undefined) {
      setNoMagicLinkConnection(false);
    }
  }, [magicLinkConnection]);

  return {
    magicLinkConnection,
    noMagicLinkConnection,
    creating,
    createMagicLinkConnection,
    deleteMagicLinkConnection,
  };
};
