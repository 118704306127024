import Input from "@kamae-apps/shared/Component/Input/Input";
import { isValidDomain } from "../../../../../../../utils";
import PrimaryButton from "@kamae-apps/shared/Component/Button/PrimaryButton";
import CancelButton from "@kamae-apps/shared/Component/Button/CancelButton";
import React, { FC } from "react";
import { CompanyData } from "../../../../../Companies";
import { useMailDomains } from "./useMailDomains";
import { MailDomainPills } from "../../../../Components/MailDomainPills";
import { ConditionalRender } from "@kamae-apps/shared/Component/ConditionalRender/ConditionalRender";

type MailDomainsProps = {
  company: CompanyData;
};

export const MailDomains: FC<MailDomainsProps> = ({ company }) => {
  const {
    mailDomains,
    domains,
    setDomains,
    newDomain,
    setNewDomain,
    writeNewDomain,
    addDomain,
    saveDomains,
    deleteDomain,
  } = useMailDomains(company);

  return (
    <div className={"flex w-full flex-col justify-between"}>
      <h1 className={"mt-2 mb-3 w-full text-center text-xl"}>Domaine mail</h1>
      <div className={"flex h-full flex-col justify-between"}>
        <div>
          <div
            className={
              "mt-3 flex w-full items-center gap-2 justify-self-start p-3"
            }
          >
            <Input
              onEnterPress={writeNewDomain}
              className={"!m-0 !w-full"}
              placeholder={"Ajouter un domaine"}
              value={newDomain}
              onChange={(e) => setNewDomain(e.target.value)}
            />
            <PrimaryButton
              disabled={!isValidDomain(newDomain)}
              onClick={addDomain}
            >
              Ajouter
            </PrimaryButton>
          </div>
          <div className={"mx-3 my-2 flex flex-wrap gap-3"}>
            <ConditionalRender condition={mailDomains === undefined}>
              <p>Chargement</p>
            </ConditionalRender>
            <ConditionalRender condition={mailDomains !== undefined}>
              <>
                {domains?.map((v) => (
                  <MailDomainPills
                    value={v}
                    key={v}
                    remove={deleteDomain}
                  />
                ))}
              </>
            </ConditionalRender>
          </div>
        </div>
        <div className={"flex w-full justify-center"}>
          <CancelButton
            cancel={"Rétablir"}
            onClick={() => setDomains(mailDomains ?? [])}
          />
          <PrimaryButton onClick={saveDomains}>Sauvegarder</PrimaryButton>
        </div>
      </div>
    </div>
  );
};
