import { useApiRequest } from "@kamae-apps/shared/Hooks/useApiRequest";
import useReload from "@kamae-apps/shared/Hooks/useReload";
import { CompanyData } from "../../../../../Companies";

type ScimKey = {
  enable: boolean;
  key?: string;
};

export const useSCIMConnection = (company: CompanyData) => {
  const { reload, doReload } = useReload();
  const scim = useApiRequest<ScimKey>(`/company/${company.id}/dir-sync`, [
    reload,
  ]);

  return {
    isScimEnable: scim?.enable,
  };
};
