import { CompanyData } from "../../../../../Companies";
import { useToast } from "@kamae-apps/shared/Component/Toast/Context";
import { useEffect, useState } from "react";
import { apiRequest } from "@kamae-apps/shared/utils";
import { APIError } from "@kamae-apps/shared/Types/API";
import { SSOConnection } from "@kamae-apps/shared/Types/Onboarding";
import { validateEmail } from "../../../../../../../../../reactfront/src/utils";
import { errorToast } from "@kamae-apps/shared/Component/Toast/ToastBuilder";

export const useSSOConnection = (company: CompanyData) => {
  const toast = useToast();
  const [ssoConnection, setSsoConnection] = useState<SSOConnection>();
  const [ssoConnectionLoading, setSsoConnectionLoading] = useState(true);
  const [ssoConnectionBadRequest, setSsoConnectionBadRequest] = useState(false);
  const [resetSsoLoading, setResetSsoLoading] = useState(false);
  const [newSsoEmail, setNewSsoEmail] = useState("");
  const [isNewAdminEmailValid, setIsNewAdminEmailValid] = useState(true);

  const updateNewAdminEmail = (value: string) => {
    setNewSsoEmail(value);
    if (!isNewAdminEmailValid) {
      setIsNewAdminEmailValid(true);
    }
  };

  const resetSsoConnection = () => {
    if (!validateEmail(newSsoEmail)) {
      setIsNewAdminEmailValid(false);
      return;
    }
    setResetSsoLoading(true);
    apiRequest<SSOConnection>(`/company/${company.id}/sso`, {
      method: "PUT",
      body: { email: newSsoEmail },
    })
      .then((response) => {
        if (response) {
          setSsoConnection(response);
          setResetSsoLoading(false);
        }
      })
      .catch(() => {
        toast.addToast(errorToast("Erreur lors du reset de l'onboarding SSO"));
        setResetSsoLoading(false);
      });
  };

  useEffect(() => {
    if (company.sso_enabled) {
      apiRequest<SSOConnection>(`/company/${company.id}/sso`, { method: "GET" })
        .then((response) => {
          if (response) {
            setSsoConnection(response);
            setSsoConnectionLoading(false);
          }
        })
        .catch((e: APIError) => {
          if (e.code !== 404) {
            setSsoConnectionBadRequest(true);
          }
          setSsoConnectionLoading(false);
        });
    } else {
      setSsoConnection(undefined);
      setSsoConnectionBadRequest(false);
      setSsoConnectionLoading(false);
    }
  }, [company]);

  return {
    ssoConnectionLoading,
    ssoConnectionBadRequest,
    ssoConnection,
    isNewAdminEmailValid,
    newSsoEmail,
    resetSsoLoading,
    updateNewAdminEmail,
    resetSsoConnection,
  };
};
